import React from "react"

import { BodyText, HighlightText, InlineLink, List, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"

const Page = () => (
  <Layout>
    <SEO title="Cupcakes" />
    <PageContainer>
      <PageContainer.Title>Get yo' cupcakes here</PageContainer.Title>
      <BodyText>The cupcake formula is simple.</BodyText>
      <List>
          <List.Item>
            <List.Text>They come in boxes of a dozen and are <HighlightText>$60 per dozen.</HighlightText></List.Text>
          </List.Item>
          <List.Item>
            <List.Text>Choose your <InlineLink to="/flavours">flavours</InlineLink>, minimum order of a dozen per flavour.</List.Text>
          </List.Item>
          <List.Item>
            <List.Text>If you want, they can be matched to a theme or colour scheme. If you don't have one, I'll make something fun for you.</List.Text>
          </List.Item>
          <List.Item>
            <List.Text>That's it! You'll get custom cupcakes just for you.</List.Text>
          </List.Item>
      </List>
      <ContactLink>I want cupcakes</ContactLink>
    </PageContainer>
  </Layout>
);

export default Page;
