import styled from "styled-components";

import { ButtonLink } from "../recipes";

const ContactLink = styled(ButtonLink).attrs({
  to: "/contact"
})`
  margin-top: ${props => props.theme.spacingRegular};
  text-align: center;
  padding: ${props => props.theme.spacingSmall};
`;

export default ContactLink;
